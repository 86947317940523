<template>
    <section class="section-base">
        <div
            v-if="$slots.background || backgroundClass"
            :class="['section-base-background', backgroundClass]"
        >
            <slot name="background" />
        </div>
        <div :class="['section-base-inner', innerClass]">
            <slot />
        </div>
    </section>
</template>

<script>
export default {
    props: {
        backgroundClass: {
            type: String,
            default: '',
        },

        innerClass: {
            type: String,
            default: '',
        },
    },
}
</script>

<style lang="scss">
.section-base {
    position: relative;
}

.section-base-background {
    position: absolute;
    @apply inset-0;
    z-index: 1;
    pointer-events: none;
}

.section-base-inner {
    position: relative;
    display: flex;
    flex-direction: column;
    min-height: 100%;
    z-index: 10;
    padding: var(--section-padding-y) var(--section-padding-x);

    &.py-big {
        padding-top: var(--section-padding-y-big);
        padding-bottom: var(--section-padding-y-big);
    }

    > header {
        text-align: center;
        margin-bottom: 3rem;
    }
}

@screen md {
    .section-base-inner {
        > header {
            margin-bottom: 4rem;
        }
    }
}

@screen lg {
    .section-base-inner {
        > header {
            margin-bottom: 5rem;
        }
    }
}
</style>